import { formatString } from "@/helpers/formatting";
import { axiosClient } from "@/services/client";
import endpoints from "@/services/endpoints";
import type { IPaginatedResponse } from "@/models/common";
import type {
  TemplateViewParams,
  EmailTemplate,
  SmsTemplate
} from "@/models/clients";
import type { TestEmailData, TestEmailPayload } from "@/models/emails";
import type { TestSmsData, TestSmsPayload } from "@/models/sms";

class CustomizationApiService {
  async getEmailTemplates(payload: TemplateViewParams) {
    const response = await axiosClient.get<IPaginatedResponse<EmailTemplate>>(
      formatString(endpoints.EMAIL_TEMPLATES.ALL),
      { params: payload }
    );
    return response.data;
  }
  async getEmailTemplate(id: string) {
    const response = await axiosClient.get<{ data: EmailTemplate }>(
      formatString(endpoints.EMAIL_TEMPLATES.SINGLE, id)
    );
    return response.data.data;
  }
  async createEmailTemplate(payload: Partial<EmailTemplate>) {
    const response = await axiosClient.post<{ data: EmailTemplate }>(
      endpoints.EMAIL_TEMPLATES.ALL,
      payload
    );
    return response.data.data;
  }
  async updateEmailTemplate(payload: Partial<EmailTemplate>) {
    if (!payload.id) {
      return;
    }
    const response = await axiosClient.put<{ data: EmailTemplate }>(
      formatString(endpoints.EMAIL_TEMPLATES.SINGLE, payload.id),
      payload
    );
    return response.data.data;
  }
  async duplicateEmailTemplate(id: number | string) {
    const response = await axiosClient.post<{ data: EmailTemplate }>(
      formatString(endpoints.EMAIL_TEMPLATES.DUPLICATE, id)
    );
    return response.data.data;
  }
  async deleteEmailTemplate(id: number | string) {
    const response = await axiosClient.delete<{ data: { deleted: boolean } }>(
      formatString(endpoints.EMAIL_TEMPLATES.SINGLE, id)
    );
    return response;
  }

  async getEmailTestData() {
    const response = await axiosClient.get<{ data: TestEmailData }>(
      formatString(endpoints.EMAIL_TEMPLATES.TEST_DATA)
    );
    return response.data.data;
  }

  async sendEmailTestData(payload: TestEmailPayload) {
    const response = await axiosClient.post(
      formatString(endpoints.EMAIL_TEMPLATES.TEST_SEND),
      payload
    );
    return response;
  }

  async getSmsTemplates(payload: TemplateViewParams) {
    const response = await axiosClient.get<IPaginatedResponse<EmailTemplate>>(
      formatString(endpoints.SMS_TEMPLATES.ALL),
      { params: payload }
    );
    return response.data;
  }
  async getSmsTemplate(id: string) {
    const response = await axiosClient.get<{ data: SmsTemplate }>(
      formatString(endpoints.SMS_TEMPLATES.SINGLE, id)
    );
    return response.data.data;
  }
  async createSmsTemplate(payload: Partial<SmsTemplate>) {
    const response = await axiosClient.post<{ data: SmsTemplate }>(
      endpoints.SMS_TEMPLATES.ALL,
      payload
    );
    return response.data.data;
  }
  async updateSmsTemplate(payload: Partial<SmsTemplate>) {
    if (!payload.id) {
      return;
    }
    const response = await axiosClient.put<{ data: SmsTemplate }>(
      formatString(endpoints.SMS_TEMPLATES.SINGLE, payload.id),
      payload
    );
    return response.data.data;
  }
  async duplicateSmsTemplate(id: number | string) {
    const response = await axiosClient.post<{ data: SmsTemplate }>(
      formatString(endpoints.SMS_TEMPLATES.DUPLICATE, id)
    );
    return response.data.data;
  }
  async deleteSmsTemplate(id: number | string) {
    const response = await axiosClient.delete<{ data: { deleted: boolean } }>(
      formatString(endpoints.SMS_TEMPLATES.SINGLE, id)
    );
    return response;
  }
  async getSmsTestData() {
    const response = await axiosClient.get<{ data: TestSmsData }>(
      formatString(endpoints.SMS_TEMPLATES.TEST_DATA)
    );
    return response.data.data;
  }

  async sendSmsTestData(payload: TestSmsPayload) {
    const response = await axiosClient.post(
      formatString(endpoints.SMS_TEMPLATES.TEST_SEND),
      payload
    );
    return response;
  }
}

const customizationApiService = new CustomizationApiService();
export default customizationApiService;
